import { Agent } from "@src/lib/interface/src";
import { uniq } from "lodash";

export const getWorkerQualifications = (agent: Agent): string[] => {
  if (!agent?.licensesData?.length) {
    return [];
  }
  const qualifications: string[] = [];
  for (const data of agent.licensesData) {
    qualifications.push(data.qualification);
  }
  return uniq(qualifications);
};
